export default {
buildTime: "2023-03-17T15:32:39Z",
commit: "5e5bbf6e7b264c577e90478def4386bb64d81d12",
npmInfo:
{
  mashlib: '1.8.8',
  npm: '8.19.4',
  node: '16.19.1',
  v8: '9.4.146.26-node.24',
  uv: '1.43.0',
  zlib: '1.2.11',
  brotli: '1.0.9',
  ares: '1.18.1',
  modules: '93',
  nghttp2: '1.47.0',
  napi: '8',
  llhttp: '6.0.10',
  openssl: '1.1.1t+quic',
  cldr: '41.0',
  icu: '71.1',
  tz: '2022f',
  unicode: '14.0',
  ngtcp2: '0.8.1',
  nghttp3: '0.7.0'
}
};
